@tailwind base;

@tailwind components;

@tailwind utilities;

h2 {
    @apply mt-2 block text-2xl leading-8 font-extrabold tracking-tight text-gray-900;
}

h3 {
    @apply mt-2 block text-xl leading-8 font-extrabold tracking-tight text-gray-700;
}

p, .txt {
    @apply text-xl text-gray-500 leading-8 mb-4;
}

a {
    @apply underline;
}

ul {
    @apply ml-4;
}

li {
    @apply list-disc text-gray-500 ml-4 mb-2;
}
